var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wallet-main" },
    [
      _c("the-wallet-side-menu"),
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "pa-2 pa-md-3 mb-14", attrs: { fluid: "" } },
            [
              _c("the-wallet-header"),
              _vm.address ? _c("module-confirmation") : _vm._e(),
              _c("router-view"),
            ],
            1
          ),
        ],
        1
      ),
      _c("the-wallet-footer", {
        attrs: { "is-offline-app": _vm.isOfflineApp },
      }),
      !_vm.isOfflineApp ? _c("enkrypt-promo-snackbar") : _vm._e(),
      _c("module-paper-wallet", {
        attrs: {
          open: _vm.showPaperWallet,
          close: _vm.closePaperWallet,
          "is-offline-app": _vm.isOfflineApp,
        },
        on: { close: _vm.closePaperWallet },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }